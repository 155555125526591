<template>
  <div class="markup-tables flex">
    <va-card class="flex xs12 mb-4" v-if="image">
      <va-card-title style="font-size: 1.5rem;">
        {{ image.name }}
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; color: grey;" v-if="downloadInProgress" />
        <va-icon :name="'fa4-download'" class="download-icon" style="padding-left: 1rem; cursor: pointer;" @click="downloadFiles" v-else />
        <div class="spinner-border" v-if="downloadInProgress"></div>
      </va-card-title>
    </va-card>

    <va-card>
      <va-card-title>
        <div class="row">
          <div class="flex xs1 md3 offset--md9" style="text-align: right;">
            <va-dropdown
              v-model="showSettingsDropdown"
              :close-on-click-outside="false"
              :close-on-content-click="false"
            >
              <template #anchor>
                <span style="font-size: 1rem; cursor: pointer;" class="download-icon">
                  <span>Model Settings</span>
                  <va-icon :name="'fa4-cog'" style="padding-left: .5rem;" />
                </span>
              </template>

              <va-dropdown-content>
                <div class="row" style="width: 25rem;">
                  <div class="flex xs12 md12">
                    <table>
                      <tbody>
                        <tr>
                          <td class="setting-dropdown-row">Show Edges</td>
                          <td class="setting-dropdown-row">
                            <va-switch
                              v-model="showEdge"
                              size="small"
                            />
                          </td>
                        </tr>
                        <tr class="setting-dropdown-row">
                          <td class="setting-dropdown-row">Background Color</td>
                          <td class="setting-dropdown-row">
                            <va-color-input
                              v-model="backgroundColor"
                              label="Background Color"
                              input-aria-label="Background Color"
                              inner-label="Background Color"
                              aria-open-color-picker-label="Background Color"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="flex xs16 md6 offset--md4 setting-dropdown-row">
                    <va-button
                      class="xs12 md12"
                      @click="applySettings"
                    >
                      Apply
                    </va-button>
                  </div>
                </div>
              </va-dropdown-content>
            </va-dropdown>
          </div>
        </div>
      </va-card-title>
      <va-content>
        <div id="viewer" style="width: 100%; height: 600px; cursor: pointer;">
        </div>
        <!-- Loader -->
        <loading v-model:active="modelLoading"
          :opacity="1"
          :can-cancel="false"
          :is-full-page="false"/>
      </va-content>
    </va-card>
  </div>
</template>

<script>
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'save-as'
import * as OV from 'online-3d-viewer'
import VueLoading from 'vue-loading-overlay'

export default {
  components: {
    loading: VueLoading
  },
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load image
      const image = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/3dimages/${this.fileId}`, { headers })
      this.image = image.data['3dImage']

      // Get image links
      const imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/3dimages/${this.fileId}/download`, { headers })
      this.imageLink = imageLink.data['3dImage']
      this.images.push({
        thumbnail: this.imageLink.jpg,
        source: this.imageLink.jpg,
        title: 'JPG'
      })
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.sessionId}/3dfiles/${this.fileId}` } })
    }
    this.loader.hide()
    await this.show3D()
  },
  beforeUnmount () {
    if (this.viewer) {
      this.viewer.Destroy()
    }
    document.getElementById('viewer').innerHTML = ''
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      session: {},
      imageLink: null,
      image: null,
      images: [],
      id: this.$route.params.id,
      sessionId: this.$route.params.sessionId,
      fileId: this.$route.params.fileId,
      options: {
        url: 'data-src'
      },
      downloadInProgress: false,
      showSettingsDropdown: false,
      modelLoading: false,
      viewer: null,
      showEdge: false,
      backgroundColor: '#fff',
      positionX: -1.5,
      positionY: 2.0,
      positionZ: 3.0,
      upX: 0.0,
      upY: 1.0,
      upZ: 0.0,
      fov: 30,
      backgroundColorR: 255,
      backgroundColorG: 255,
      backgroundColorB: 255,
      backgroundColorO: 255
    }
  },
  methods: {
    downloadFiles: async function () {
      this.$vaToast.init({
        message: 'Downloading Files. Please wait.',
        iconClass: 'fa-star-o',
        position: 'bottom-right',
        duration: 6000,
        fullWidth: false,
        color: 'primary',
      })
      try {
        this.downloadInProgress = true
        const fileId = this.fileId
        const zip = new JSZip()
        const files = zip.folder(fileId)

        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Get image links
        let imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/3dimages/${fileId}/download`, { headers })
        let res = await axios({
          url: imageLink.data['3dImage'].obj,
          method: 'GET',
          responseType: 'arraybuffer',
        })
        const objBlob = new Blob([res.data], {
          type: 'model/obj'
        })

        imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/3dimages/${fileId}/download`, { headers })
        res = await axios({
          url: imageLink.data['3dImage'].mtl,
          method: 'GET',
          responseType: 'arraybuffer',
        })
        const mtlBlob = new Blob([res.data], {
          type: 'model/mtl'
        })

        imageLink = await axios.get(`${process.env.VUE_APP_API_URL}/api/identifiers/${this.id}/sessions/${this.sessionId}/3dimages/${fileId}/download`, { headers })
        res = await axios({
          url: imageLink.data['3dImage'].jpg,
          method: 'GET',
          responseType: 'arraybuffer',
        })
        const jpgBlob = new Blob([res.data], {
          type: 'image/jpg'
        })

        files.file('mesh.obj', objBlob, {base64: true})
        files.file('mesh.mtl', mtlBlob, {base64: true})
        files.file('texture.jpg', jpgBlob, {base64: true})

        zip.generateAsync({type:'blob'}).then(function(content) {
            saveAs(content, `${fileId}.zip`);
        })
        this.downloadInProgress = false
      } catch (error) {
        this.downloadInProgress = false
        this.$router.push({ name: 'login', query: { redirect: `/pages/identifiers/${this.id}/sessions/${this.sessionId}/3dfiles/${this.fileId}` } })
      }
    },
    convertHexToRGBA (hexCode, opacity = 1) {
        let hex = hexCode.replace('#', '')

        if (hex.length === 3) {
            hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
        }

        const r = parseInt(hex.substring(0, 2), 16)
        const g = parseInt(hex.substring(2, 4), 16)
        const b = parseInt(hex.substring(4, 6), 16)

        /* Backward compatibility for whole number based opacity values. */
        if (opacity > 1 && opacity <= 100) {
            opacity = opacity / 100
        }

        this.backgroundColorR = r
        this.backgroundColorG = g
        this.backgroundColorB = b
        this.backgroundColorO = opacity * 255
    },
    show3D () {
      const self = this
      self.modelLoading = true
      document.getElementById('viewer').innerHTML = ''
      try {
        // OV.SetExternalLibLocation ('/Users/chaitya/Galileo/armada-dashboard/node_modules/online-3d-viewer/libs')
        // get the parent element of the viewer
        const parentDiv = document.getElementById('viewer')
        const viewer = new OV.EmbeddedViewer(parentDiv, {
          camera : new OV.Camera (
            new OV.Coord3D (self.positionX, self.positionY, self.positionZ),
            new OV.Coord3D (0.0, 0.0, 0.0),
            new OV.Coord3D (self.upX, self.upY, self.upZ),
            this.fov
          ),
          // projectionMode: 2,
          // navigationMode: 2,
          backgroundColor : new OV.RGBAColor (self.backgroundColorR, self.backgroundColorG, self.backgroundColorB, self.backgroundColorO),
          defaultColor : new OV.RGBColor (200, 200, 200),
          edgeSettings : new OV.EdgeSettings (self.showEdge, new OV.RGBColor (0, 0, 0), 1),
          onModelLoaded: self.modelLoaded
        })
        // load a model providing model urls
        viewer.LoadModelFromUrlList([
          self.imageLink.obj,
          self.imageLink.mtl,
          self.imageLink.jpg
        ])

        self.viewer = viewer.GetViewer()
      } catch (error) {
        console.log(error)
      }
    },
    applySettings () {
      // Restore to exact view
      this.positionX = this.viewer.camera.position.x
      this.positionY = this.viewer.camera.position.y
      this.positionZ = this.viewer.camera.position.z
      this.upX = this.viewer.camera.up.x
      this.upY = this.viewer.camera.up.y
      this.upZ = this.viewer.camera.up.z
      this.fov = this.viewer.camera.fov
      this.convertHexToRGBA(this.backgroundColor)

      this.viewer.Destroy()
      document.getElementById('viewer').innerHTML = ''
      this.showSettingsDropdown = false
      this.show3D()
    },
    modelLoaded () {
      this.modelLoading = false
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .download-icon:hover {
    color: #154EC1;
  }

  #stat-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .configure-md {
    padding-top: 1rem;
  }

  .configure-sub-md {
    padding-left: 2rem;
  }

  .image {
    cursor: pointer;
    width: 100%;
  }

  .image2 {
    cursor: pointer;
    width: 45%;
    margin-right: 1rem;
    margin-left: 0.75rem;
  }

  .image3 {
    cursor: pointer;
    width: 45%;
  }

  .image4 {
    cursor: pointer;
    width: 45%;
    margin-left: 28%;
  }

  #pigment-table th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #pigment-table tr > td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .spinner-border {
    --bs-spinner-width: 1.5rem;
    --bs-spinner-height: 1.5rem;
  }

  .settings-icon:hover {
    color: #154EC1;
    cursor: pointer;
  }

  .setting-dropdown-row {
    padding: .75rem;
  }
</style>
